<template>
    <section class="features">
        <div class="container">
            <div class="row features-block">
                <div class="col-12 col-md-12 col-lg-4">
                    <span>Ваш надежный партнер для покупки, продажи и обмена криптовалют</span>
                </div>
                <div class="col-12 col-md-12 col-lg-7">
                    <p>Одна из ведущих платформ для обмена криптовалют. Мы предлагаем самые быстрые транзакции с минимальными комиссиями, надежную защиту данных, возможность покупки криптовалюты с помощью карт, а также ряд других преимуществ.</p>
                </div>
            </div>
            <div class="row features-container">
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="features-wrap">
                        <div class="features-title">
                            <i class="features-icon x-icon-free"></i>
                            <span class="features-name">Нет комиссии</span>
                        </div>
                        <p class="features-text">Все операции проводятся честно и прозрачно, без скрытых комиссий и условий.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="features-wrap">
                        <div class="features-title">
                            <i class="features-icon x-icon-security"></i>
                            <span class="features-name">Безопасность данных</span>
                        </div>
                        <p class="features-text">Мы используем современные технологии и методы защиты, чтобы обеспечить безопасность ваших средств и данных.</p>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-4">
                    <div class="features-wrap">
                        <div class="features-title">
                            <i class="features-icon x-icon-card"></i>
                            <span class="features-name">Удобство</span>
                        </div>
                        <p class="features-text">Интуитивно понятный интерфейс нашего сайта позволяет легко совершать операции даже начинающим пользователям.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>