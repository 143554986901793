<template>
    <div class="dashboard-title">Пополнить баланс</div>
    <div class="tab horizontal default-color">
        <router-link to="/dashboard/refill/cryptocurrency" class="tab-button">Криптовалюты</router-link>
        <router-link to="/dashboard/refill/fiat" class="tab-button">Фиат</router-link>
        <router-link to="/dashboard/refill/terminals" class="tab-button">Терминалы</router-link>
        <router-link to="/dashboard/refill/cash" class="tab-button">Наличные</router-link>
    </div>
    <p class="text success mt-4">При пополнение баланса любым из способов, Ваша сумма платежа будет конвертирована в USDT по курсу нашего сайта валюты пополнения. Ваш баланс отображается в USDT (Tether)</p>
    <div class="dashboard-list mt-4">
        <div class="row">
            <template v-if="category === 'cryptocurrency' && currencies">
                <div class="col-12 col-md-12 col-lg-12 pb-4">
                    <input type="text" name="search" class="form-input" v-model="searchValue" placeholder="Поиск" autocomplete="off">
                </div>
                <template v-for="item in currencies" :key="item">
                    <div class="col-12 col-md-6 col-lg-6 col-xl-4" v-if="item.can_deposit === true && search(item)">
                        <div class="list-item" v-on:click="getDepositAddress(item)">
                            <img :src="item.logo" :alt="item.name">
                            <div class="list-item-wrap">
                                <div>
                                    <b>{{ item.currency }}</b>
                                    <span>{{ item.name }}</span>
                                </div>
                                <a class="list-item-button d-none" v-if="item.commission">{{ item.commission }}%</a>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <template v-if="category === 'fiat'">
                <div class="col-12 col-md-6 col-lg-6 col-xl-4" v-for="item in getMethods" :key="item">
                    <div class="list-item" v-on:click="getMethodDetail(item)">
                        <img :src="item.logo" :alt="item.name">
                        <div class="list-item-wrap">
                            <div>
                                <b>{{ item.currency }}</b>
                                <span>{{ item.name }}</span>
                            </div>
                            <a class="list-item-button">{{ item.commission }}%</a>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="category === 'terminals'">
                <div class="col-12 col-md-6 col-lg-6 col-xl-4" v-for="item in getMethods" :key="item">
                    <div class="list-item" v-on:click="getMethodDetail(item)">
                        <img :src="item.logo" :alt="item.name">
                        <div class="list-item-wrap">
                            <div>
                                <b>{{ item.currency }}</b>
                                <span>{{ item.name }}</span>
                            </div>
                            <a class="list-item-button" v-if="item.commission">{{ item.commission }}%</a>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="category === 'cash'">
                <div class="col-12 col-md-6 col-lg-6 col-xl-4" v-for="item in getMethods" :key="item">
                    <div class="list-item" v-on:click="getMethodDetail(item)">
                        <img :src="item.logo" :alt="item.name">
                        <div class="list-item-wrap">
                            <div>
                                <b>{{ item.currency }}</b>
                                <span>{{ item.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="!getMethods">
                <span class="icon-text empty">Нет данных</span>
            </template>
        </div>
    </div>
    <div class="modal-window" v-if="modalStatus">
        <div class="modal-close" v-on:click="closeModal"></div>
        <div class="modal-content" v-if="category === 'cryptocurrency'">
            <h1 class="modal-title text-center">{{ detail.name }} депозитный адрес</h1>
            <div class="modal-wrap my-4">
                <div class="profile-info-column mt-0 refill">
                    <div>
                        <span>Минимальная сумма депозита</span>
                        <b>{{ detail.currency == 'USDT' ? 5.00 : detail.min_qty }} {{ detail.currency }}</b>
                    </div>
                </div>
            </div>
            <span class="modal-subtitle text-center">Если отправить криптовалюту на неверный адрес (например, биткойн на адрес Bitcoin Cash), криптовалюта будет потеряна</span>
            <div class="form">
                <div class="modal-qr-image">
                    <img :src="depositAddress.qrcode_url" :alt="detail.name">
                </div>
                <label class="form-label">Депозитный адрес <b>{{ detail.currency }} <template v-if="detail?.currency == 'USDT'">TRC20</template></b></label>
                <div class="form-group">
                    <input type="text" class="form-input" :value="depositAddress.deposit_address" ref="address" readonly>
                </div>
                <button class="button block lg mt-4" v-on:click="copy('address')">Скопировать</button>
                <button class="button button-danger block lg mt-3" v-on:click="closeModal">Закрыть</button>
            </div>
        </div>
        <div class="modal-content" v-if="category === 'fiat' && !fiatDetail">
            <h1 class="modal-title text-center mb-4">Пополнить через "{{ detail.name }}"</h1>
            <div class="modal-wrap">
                <div class="row">
                    <div class="col-12 col-md-6 m-mb">
                        <div class="profile-info-column mt-0 refill">
                            <div>
                                <span>Минимальная сумма</span>
                                <b>{{ detail.min_qty }} {{ detail.currency }}</b>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="profile-info-column mt-0 refill">
                            <div>
                                <span>Комиссия</span>
                                <b>{{ detail.commission }}%</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form mt-4">
                <label class="form-label">Введите сумму в <b>{{ detail.currency }}</b></label>
                <div class="form-group">
                    <input type="number" class="form-input" v-model="amount" placeholder="Введите сумму" required>
                </div>
                <button class="button block lg mt-4" v-on:click="createFiatMethod" :disabled="loading">Продолжить</button>
            </div>
        </div>
        <div class="modal-content" v-if="category === 'fiat' && fiatDetail">
            <h1 class="modal-title text-center mb-3">Подтверждение платежа</h1>
            <div class="dashboard-table px-0">
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к зачислению</div>
                    <div class="dashboard-table-column">{{ fiatDetail.confirm?.debit }} USDT</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к оплате</div>
                    <div class="dashboard-table-column">{{ fiatDetail.confirm?.amount }} {{ fiatDetail.confirm?.currency }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Наша комиссия</div>
                    <div class="dashboard-table-column">{{ fiatDetail.confirm?.commission }} {{ fiatDetail.confirm?.currency }}</div>
                </div>
            </div>
            <template v-if="fiatDetail.confirm.qrCode">
                <div class="dashboard-table-row table-row-v2 justify-content-center mb-3">
                    <div class="dashboard-table-column text-wrap text-center">Для проведения платежа, отсканируйте QR-код в приложении банка</div>
                </div>
                <div class="modal-qr-image modal-qr-mkassa mb-3">
                    <img :src="fiatDetail.confirm?.qrCode" :alt="fiatDetail.confirm?.description">
                </div>
            </template>
            <!-- <template v-if="fiatDetail.confirm.deepLink">
                <div class="dashboard-table-row table-row-v2 justify-content-center mb-3">
                    <div class="dashboard-table-column text-wrap text-center">Или откройте ссылку для оплаты в MBANK</div>
                </div>
                <a :href="fiatDetail.confirm.deepLink" class="mbank-button"></a>
            </template> -->
            <div class="custom-form" v-html="fiatDetail.form"></div>
            <button class="button button-danger block lg mt-3" v-on:click="closeModal">Закрыть</button>
        </div>
        <div class="modal-content" v-if="category === 'terminals'">
            <h1 class="modal-title text-center mb-4">Пополнить через "{{ detail?.name }}"</h1>
            <p class="modal-text" v-html="detail.info?.description"></p>
            <button class="button block lg" v-on:click="closeModal">Закрыть</button>
        </div>
        <div class="modal-content" v-if="category === 'cash'">
            <h1 class="modal-title text-center mb-4">Пополнить через "{{ detail?.name }}"</h1>
            <p class="modal-text text-center" v-html="detail.info?.description"></p>
            <button class="button block lg" v-on:click="closeModal">Закрыть</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchValue: '',
            modalStatus: false,
            category: '',
            detail: '',
            amount: ''
        }
    },
    created() {
        this.getCurrentCategory()
        if (this.$route.query['currency']) {
            this.getDepositAddress(this.$store.getters.getCurrencies[this.$route.query['currency']])
        }
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        currencies() {
            return this.$store.getters.getCurrencies
        },
        getMethods() {
            return this.$store.getters.getMethods
        },
        depositAddress() {
            return this.$store.getters.getDepositAddress
        },
        fiatDetail() {
            return this.$store.getters.getFiatMethod
        },
        getMKassaPaymentStatus() {
            return this.$store.getters.getMKassaPaymentStatus
        }
    },
    watch: {
        $route() {
            this.getCurrentCategory()
        },
        depositAddress() {
            this.showModal()
        },
        fiatDetail() {
            if (this.fiatDetail && this.fiatDetail.confirm.qrCode !== '') {
                this.checkMKassaPayment()
            }
        },
        getMKassaPaymentStatus() {
            if (this.getMKassaPaymentStatus.status.value == '2') {
                this.$store.dispatch('notification', { message: 'Оплата прошла успешно!', type: 'success' })
                this.$store.dispatch('clearFiatMethod')
                this.$router.push('/dashboard/operations')
                clearInterval(this.intervalId)
            }
            if (this.getMKassaPaymentStatus.status.value == '3') {
                this.$store.dispatch('notification', { message: 'Оплата отменена!' })
                this.$store.dispatch('clearFiatMethod')
                this.$router.push('/dashboard/operations')
                clearInterval(this.intervalId)
            }
        }
    },
    methods: {
        showModal() {
            this.modalStatus = true
        },
        closeModal() {
            this.modalStatus = false
            if (this.category === 'fiat') {
                this.amount = ''
                this.$store.dispatch('clearFiatMethod')
            }
        },
        getCurrentCategory() {
            this.category = this.$router.currentRoute.value.params.category
            this.$store.dispatch('getMethods', this.category)
        },
        getDepositAddress(item) {
            this.detail = item
            this.$store.dispatch('getDepositAddress', item.slug)
        },
        getMethodDetail(item) {
            this.detail = item
            this.showModal()
        },
        createFiatMethod() {
            if (this.amount < this.detail.min_qty) {
                this.$store.dispatch('notification', { message: 'Минимальная сумма пополнения ' + this.detail.min_qty + ' ' + this.detail.currency })
            } else {
                const data = {
                    sum: this.amount,
                    method: this.detail.slug
                }
                this.$store.dispatch('createFiatMethod', data)
            }
        },
        copy(ref) {
            this.$refs[ref].select()
            document.execCommand('copy')
            this.$refs[ref].blur()
            window.getSelection().removeAllRanges()
            this.$store.dispatch('notification', { message: 'Скопировано', type: 'success' })
        },
        checkMKassaPayment() {
            const self = this
            this.intervalId = setTimeout(() => {
                self.$store.dispatch('checkMKassaPayment', { 'guid': self.fiatDetail.confirm.id })
                this.checkMKassaPayment()
            }, 3500)
        },
        search(item) {
            return (item.currency.toLowerCase().search(this.searchValue.toLowerCase()) !== -1 || item.name.toLowerCase().search(this.searchValue.toLowerCase()) !== -1) ? true : false
        }
    }
}
</script>