<template>
    <Header></Header>
    <div class="container min-height padding-top-default">
        <div class="rules-page z-index">
            <div class="row pb-4">
                <h1 class="text-center">Политика конфиденциальности</h1>
                <br>
                <p>1. Настоящий документ определяет политику конфиденциальности Общества с ограниченной ответственностью
                    «Биток плюс» (далее — Общество или Оператор), правила Оператора обмена виртуальных активов в
                    отношениях
                    с клиентами (субъектами персональных данных), и раскрывает сведения о реализованных мерах по
                    обеспечению
                    безопасности персональных данных у Оператора с целью защиты прав и свобод клиентов при обработке их
                    персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную, семейную и
                    коммерческую тайну.
                </p>
                <p>2. Настоящая Политика конфиденциальности (далее — Политика) разработана в соответствии с Конституцией
                    Кыргызской Республики, законами Кыргызской Республики «Об информации персонального характера», «О
                    виртуальных активах», «О противодействии финансированию террористической деятельности и легализации
                    (отмыванию) преступных доходов», Трудовым кодексом Кыргызской Республики, Положением «О деятельности
                    операторов обмена виртуальных активов и ведении Реестра операторов обмена виртуальных активов»,
                    утвержденным постановлением Кабинета Министров Кыргызской Республики «О вопросах регулирования
                    отношений, возникающих при обороте виртуальных активов» от 16.09.2022г. № 514, иными законами и
                    подзаконными актами Кыргызской Республики, определяющими случаи и особенности обработки персональных
                    данных и обеспечения безопасности и конфиденциальности такой информации, правила оператора обмена
                    виртуальных активов в отношениях с клиентами.
                </p>
                <p>3. Положения настоящей Политики являются обязательными для исполнения всеми сотрудниками,
                    осуществляющими обработку персональных данных, и клиентами Общества.
                </p>
                <p>4. Положения настоящей Политики являются основой для организации работы по обработке персональных
                    данных
                    у Общества, в том числе для разработки внутренних нормативных документов, регламентирующих обработку
                    и
                    защиту персональных данных у Общества.
                </p>
                <p>5. В случае если отдельные положения настоящей Политики войдут в противоречие с законодательством
                    Кыргызской Республики, применяются положения действующего законодательства
                </p>
                <p>Настоящая Политика является документом, к которому обеспечивается неограниченный доступ. Для
                    обеспечения неограниченного доступа Политика, в частности, опубликована на официальном сайте
                    Общества по адресу: www.bitok.kg.
                    Запросы субъектов персональных данных в отношении обработки их персональных данных Оператором
                    принимаются по адресу: Кыргызская Республика, г. Бишкек, ул. Усенбаева, д. 138а, офис 3.
                    Также субъекты персональных данных могут направить свой запрос, подписанный усиленной
                    квалицированной электронной подписью на адрес электронной Срок рассмотрения
                    обращений не превышает 30 (тридцати) дней со дня обращения.</p>
                <p>2. Термины и определения</p>
                <p>Автоматизированная обработка персональных данных — обработка персональных данных с помощью средств
                    вычислительной техники.
                    Блокирование персональных данных — временное прекращение обработки персональных данных (за
                    исключением
                    случаев, когда обработка необходима для уточнения персональных данных).
                </p>
                <p>Информационная система персональных данных — совокупность содержащихся в базах данных персональных
                    данных и обеспечивающих их обработку информационных технологий и технических средств.
                    Обезличивание персональных данных — действия, в результате которых невозможно определить без
                    использования дополнительной информации принадлежность персональных данных конкретному субъекту
                    персональных данных.
                </p>
                <p>Обработка персональных данных — любое действие (операция) или совокупность действий (операций),
                    совершаемых с использованием средств автоматизации или без использования таких средств с
                    персональными
                    данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
                    изменение),
                    извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
                    блокирование, удаление, уничтожение персональных данных.
                </p>
                <p>Оператор — Общество, самостоятельно или совместно с другими лицами (государственными органами,
                    муниципальными органами, юридическими или физическими лицами) организующее и (или) осуществляющее
                    обработку персональных данных, а также определяющее цели обработки персональных данных, состав
                    персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
                    Персональные данные — любая информация, относящаяся к прямо или косвенно определенному или
                    определяемому
                    физическому лицу (субъекту персональных данных).
                </p>
                <p>Предоставление персональных данных — действия, направленные на раскрытие персональных данных
                    определенному лицу или определенному кругу лиц.
                </p>
                <p>Распространение персональных данных — действия, направленные на раскрытие персональных данных
                    неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными
                    неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой
                    информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к
                    персональным данным каким-либо иным способом.
                </p>
                <p>Трансграничная передача персональных данных — передача персональных данных на территорию иностранного
                    государства органу власти иностранного государства, иностранному физическому или иностранному
                    юридическому лицу.
                </p>
                <p>Уничтожение персональных данных — действия, в результате которых невозможно восстановить содержание
                    персональных данных в информационной системе персональных данных и (или) в результате которых
                    уничтожаются материальные носители персональных данных.
                </p>
                <p>Блокирование персональных данных — временное прекращение обработки персональных данных (за
                    исключением
                    случаев, когда обработка необходима для уточнения персональных данных).
                </p>
                <p>Информационная система персональных данных — совокупность содержащихся в базах данных персональных
                    данных и обеспечивающих их обработку информационных технологий и технических средств.
                </p>
                <p>Обезличивание персональных данных — действия, в результате которых невозможно определить без
                    использования дополнительной информации принадлежность персональных данных конкретному субъекту
                    персональных данных.
                </p>
                <p>Обработка персональных данных — любое действие (операция) или совокупность действий (операций),
                    совершаемых с использованием средств автоматизации или без использования таких средств с
                    персональными
                    данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
                    изменение),
                    извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
                    блокирование, удаление, уничтожение персональных данных.
                </p>
                <p>Оператор — Общество, самостоятельно или совместно с другими лицами (государственными органами,
                    муниципальными органами, юридическими или физическими лицами) организующее и (или) осуществляющее
                    обработку персональных данных, а также определяющее цели обработки персональных данных, состав
                    персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.
                </p>
                <p>Персональные данные — любая информация, относящаяся к прямо или косвенно определенному или
                    определяемому
                    физическому лицу (субъекту персональных данных).
                </p>
                <p>Предоставление персональных данных — действия, направленные на раскрытие персональных данных
                    определенному лицу или определенному кругу лиц.
                </p>
                <p>Распространение персональных данных — действия, направленные на раскрытие персональных данных
                    неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными
                    неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой
                    информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к
                    персональным данным каким-либо иным способом.
                </p>
                <p>Трансграничная передача персональных данных — передача персональных данных на территорию иностранного
                    государства органу власти иностранного государства, иностранному физическому или иностранному
                    юридическому лицу.
                </p>
                <p>Уничтожение персональных данных — действия, в результате которых невозможно восстановить содержание
                    персональных данных в информационной системе персональных данных и (или) в результате которых
                    уничтожаются материальные носители персональных данных.</p>
            </div>
        </div>
    </div>
    <Footer></Footer>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    data() {
        return {
        }
    },
    components: {
        Header,
        Footer
    }
}
</script>