<template>
    <h1 class="dashboard-title" v-if="detail.logo && detail.name">
        <img :src="detail.logo" :alt="detail.name" class="title-icon">
        <span>Вывести {{ detail.name }}</span>
    </h1>
    <p class="text success mb-0"><b>Внимание</b>, не используйте платформу для совершения незаконных операций.</p>
    <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-8">
            <div class="row" v-if="info && network">
                <div class="col-12 col-md-6">
                    <div class="dashboard-personal-wrap ellipse">
                        <div class="profile-info-column mt-0 refill">
                            <div>
                                <span>Срок исполнения</span>
                                <b>Моментально</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="dashboard-personal-wrap ellipse">
                        <div class="profile-info-column mt-0 refill">
                            <div>
                                <span>Минимальная сумма</span>
                                <b>{{ network.min_qty }} {{ currency }}</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="dashboard-personal-wrap ellipse">
                        <div class="profile-info-column mt-0 refill">
                            <div>
                                <span>Наша комиссия</span>
                                <b>{{ info && info.commission ? info.commission : '0.00' }}%</b>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="dashboard-personal-wrap ellipse">
                        <div class="profile-info-column mt-0 refill">
                            <div>
                                <span>Комиссия сети</span>
                                <b>{{ network.commission }} {{ currency }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-personal-wrap ellipse ellipse-after">
                <form v-on:submit.prevent="onsubmit" class="form position-relative z-index" autocomplete="off">
                    <label class="form-label">Выберите сеть</label>
                    <div class="form-group mb-4">
                        <input type="text" class="form-input" placeholder="Выберите сеть" readonly :value="selectName ? selectName : network.name" v-on:click="showSelectModal">
                    </div>
                    <label class="form-label d-flex justify-content-between">Сумма к списанию <span><span class="md-none">Баланс:</span> <b class="max-balance" v-on:click="setMaxAmount">{{ balance }}</b> {{ currency }}</span></label>
                    <div class="form-group mb-4 position-relative">
                        <input type="number" name="amount" class="form-input" step="any" placeholder="Введите сумму" v-model="amount" @input="calculate" required :disabled="loading">
                        <span class="form-icon icon-max-amount ease" v-on:click="setMaxAmount"></span>
                    </div>
                    <label class="form-label">Сумма к зачислению</label>
                    <div class="form-group mb-4">
                        <input type="number" name="quantity" class="form-input" placeholder="Сумма к зачислению" v-model="quantity" disabled>
                    </div>
                    <label class="form-label">Введите адрес {{ currency }} кошелька</label>
                    <div class="form-group mb-4 position-relative">
                        <input type="text" name="address" class="form-input" placeholder="Введите адрес" v-model="address" required :disabled="loading">
                        <span class="form-icon icon-paste ease" v-on:click="paste"></span>
                    </div>
                    <button class="button block lg mt-4" :disabled="loading" type="submit">Продолжить</button>
                </form>
            </div>
        </div>
    </div>
    <div class="modal-window" v-if="modalStatus">
        <div class="modal-close" v-on:click="closeModal"></div>
        <form class="modal-content" v-on:submit.prevent="confirmWithdraw" autocomplete="off">
            <h1 class="modal-title text-center mb-3">Подтверждение платежа</h1>
            <div class="dashboard-table px-0" v-if="confirm">
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к списанию</div>
                    <div class="dashboard-table-column">{{ confirm.amount }} {{ currency }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Сумма к зачислению</div>
                    <div class="dashboard-table-column">{{ quantity }} {{ currency }}</div>
                </div>
                <div class="dashboard-table-row table-row-v2">
                    <div class="dashboard-table-column">Комиссия</div>
                    <div class="dashboard-table-column">{{ confirm.commission }} {{ currency }}</div>
                </div>
            </div>
            <template v-if="twofactor">
                <label class="form-label mt-3">Google 2Fa Authenticator</label>
                <div class="form-group">
                    <input type="number" class="form-input" ref="code" placeholder="Введите защитный код" :disabled="loading" required>
                </div>
            </template>
            <button class="button block lg" type="submit" :disabled="loading">Подтвердить</button>
        </form>
    </div>
    <Select :data="SelectData"></Select>
</template>

<script>
import Select from '@/components/Account/Select.vue'

export default {
    data() {
        return {
            modalStatus: false,
            quantity: '',
            currency: '',
            address: '',
            balance: '',
            network: '',
            amount: '',
            SelectData: {
                title: 'Выберите сеть',
                list: '',
                ListName: 'name',
                ListValue: '',
                LabelName: '',
                LabelValue: 'commission'
            }
        }
    },
    components: {
        Select
    },
    created() {
        this.$store.dispatch('getProfile')
        this.currency = this.$router.currentRoute.value.params.currency
        this.$store.dispatch('getWithdrawDetail', this.currency)
    },
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        profile() {
            return this.$store.getters.getProfile
        },
        selectName() {
            return this.$store.getters.selectName
        },
        detail() {
            return (this.$store.getters.getCurrencies)
            ? this.$store.getters.getCurrencies[this.currency]
            : this.$store.dispatch('getCurrencies')
        },
        info() {
            return this.$store.getters.getWithdrawDetail
        },
        confirm() {
            return this.$store.getters.getWithdrawConfirm
        },
        twofactor() {
            return this.$store.getters.twofactor
        }
    },
    watch: {
        profile() {
            if (this.profile.verification.value !== '2') {
                this.$router.push('/dashboard/settings/verification')
                this.$store.dispatch('notification', { message: 'Требуется проверка KYC' })
            }
        },
        detail() {
            if(this.detail.can_withdraw !== true) {
                this.$router.push('/dashboard/withdraw')
            } else {
                this.balance = this.$store.getters.getCurrencies[this.currency].balance
            }
        },
        info() {
            if(this.currency == 'USDT') {
                this.network = Object.values(this.info?.networks).find(function(el) { return el.network === 'TRC20' })
            } else {
                this.network = this.info ? this.info.networks[1] : ''
            }
            this.SelectData.list = this.info ? this.info.networks : []
            this.SelectData.LabelName = 'Комиссия: %s ' + this.currency
        },
        confirm() {
            this.showModal()
        },
        selectName() {
            this.network = this.info.networks[this.$store.getters.selectValue]
            this.calculate()
        }
    },
    methods: {
        async paste() {
            const text = await navigator.clipboard.readText()
            this.address = text
        },
        setMaxAmount() {
            this.amount = this.balance
            this.calculate()
        },
        showModal() {
            this.$store.dispatch('twofactor', false)
            this.modalStatus = true
        },
        closeModal() {
            this.modalStatus = false
        },
        notification(message, type) {
            this.$store.dispatch('notification', { message, type })
        },
        showSelectModal() {
            this.$store.dispatch('setModalStatus', true)
        },
        numberFormat(number, float) {
            var string, result
            if(String(number).includes('.')) {
                string = String(number).split('.')
                result = (float > 0) ? string[0] +'.'+ string[1].substring(0, float) : string[0]
            } else {
                result = number
            }
            return result
        },
        calculate() {
            if(this.amount < parseFloat(this.network.min_qty)) {
                this.quantity = ''
            } else {
                this.quantity = this.amount - this.network.commission
                this.quantity = this.numberFormat(this.quantity, this.detail.decimal)
            }
        },
        onsubmit() {
            if(this.quantity < parseFloat(this.network.min_qty)) {
                this.notification('Минимальная сумма ' + this.network.min_qty +' '+ this.currency)
            } else {
                if(!this.address || this.address === '') {
                    this.notification('Введите адрес кошелька')
                } else {
                    const data = {
                        sum: this.amount,
                        currency: this.currency,
                        wallet: this.address,
                        network: this.network.network
                    }
                    console.log(data)
                    this.$store.dispatch('createWithdraw', data)
                }
            }
        },
        confirmWithdraw() {
            const data = {
                'guid': this.confirm.id
            }
            if(this.twofactor) {
                data['2fa_otp'] = String(this.$refs.code.value)
            }
            this.$store.dispatch('confirmWithdraw', data)
        }
    }
}
</script>